import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "gatsby";
import Seo from "./SEO";

class Header extends React.Component {
    render() {
        return (
            <div>
                <Seo pageName={this.props.pageName} />
                <Container>
                    <Row>
                        <Col md={12} className={"header section-padding"}>
                            <h1><Link to={"/"}>Mohamad Hallak</Link></h1>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Header;
