import React from "react";

import Pagination from 'react-bootstrap/Pagination';

import CardRow from "./card-row";

class CardGrid extends React.Component {
    constructor(props) {
        super(props);

        this.postsPerPage = this.props.postsPerPage;
        this.postsPerRow = 3;

        this.pagePosts = this.splitPostsToChunks(this.props.posts, this.postsPerPage);

        this.numberOfPages = this.props.posts.length <= this.postsPerPage ? 0 : Math.ceil(this.props.posts.length / this.postsPerPage);

        this.state = {
            activePage: 0,
        }

        this.updatePage = this.updatePage.bind(this);
    }

    splitPostsToChunks(posts, size) {
        var chunks = [];

        for (var i = 0; i < posts.length; i += size) {
            chunks.push(posts.slice(i, i + size));
        }

        return chunks;
    }

    updatePage(e) {
        this.setState({
            activePage: parseInt(e.target.text),
        });
    }

    render() {
        const activePagePosts = this.pagePosts[this.state.activePage];
        const postRows = this.splitPostsToChunks(activePagePosts, 3);

        const paginationNumbers = [];

        for (var number = 0; number < this.numberOfPages; number++) {
            paginationNumbers.push(
                <Pagination.Item
                    key={number}
                    onClick={this.updatePage}
                    active={number === this.state.activePage}
                    disabled={this.numberOfPages === 0}
                >
                    {number}
                </Pagination.Item>
            )
        }

        return (
            <div>
                <div className={"card-deck"}>
                    {postRows.map((postChunk, index) => {
                        return (
                            <CardRow posts={postChunk} key={index} />
                        )
                    })}
                </div>

                <Pagination>
                    {paginationNumbers}
                </Pagination>
            </div>
        )
    }
}

export default CardGrid;
