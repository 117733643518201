import React from "react";

import PostCard from "./card";

import { Row, Col } from "react-bootstrap";

class CardRow extends React.Component {
    render() {
        var chunk = this.props.posts;

        return (
            <Row className={"post-card-row"}>
                {chunk.map(({ node }, index) => {
                    return (
                        <Col lg={4} md={6} sm={12} xs={12} key={index}>
                            <PostCard node={node} key={index} />
                        </Col>
                    )
                })}
            </Row>
        )
    }
}

export default CardRow;
