import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "gatsby";

class PostCard extends React.Component {
    render() {
        var node = this.props.node;

        return (
            <Card className={"post-card"}>
                <Card.Img variant="top" src={node.frontmatter.featuredImage.childImageSharp.fluid.src} alt={node.frontmatter.title} />
                <Card.Body className={"post-card-body"}>
                    <Card.Title><Link to={node.fields.slug}>{node.frontmatter.title}</Link></Card.Title>
                    <Card.Text>
                        {node.excerpt}
                    </Card.Text>
                </Card.Body>

                <Card.Footer>
                    <div className={"post-tags"}>
                        <div className={"tag-icon"}>
                            <i className={"fas fa-tags"}></i>
                        </div>
                        {node.frontmatter.tags.slice(0, 1).map((tag) => {
                            return (
                                <Card.Link href={"/tags/" + tag.toString().toLowerCase()} className={""} key={tag}><span>{tag}</span></Card.Link>
                            )
                        })}
                    </div>
                    <div className={"post-meta"}>
                        <i className={"fas fa-calendar-day"}></i>
                        <span>{node.frontmatter.date}</span>
                    </div>
                </Card.Footer>
            </Card>
        )
    }
}

export default PostCard;
